<template>
  <div>
    <carrusel />

    <v-dialog
      v-model="DialogResetPIN"
      persistent
      max-width="290"
    >
      <v-card>
         <v-toolbar
              color="primary"
              dark
            >
              Actualizar PIN
            </v-toolbar>

        <v-card-text class="mt-3 text-h6">Por favor digite un nuevo PIN para inicio de sesión.</v-card-text>

        <v-form
          v-on:submit.prevent="UpdatePIN()"
          ref="form"
          lazy-validation
        >
          <v-col
            cols="12"
          >
            <v-text-field
            :type="tipopin ? 'text' : 'password'"
              v-model="New.Pin"
              label="PIN"
              hint="Escriba nuevo PIN para ventas."
              persistent-hint
              :loading="New.Pin == '' ? true : false"
              :rules="pinRules"
            ></v-text-field>

            <v-checkbox
            class="mt-n1"
            v-model="tipopin"
            label="Ver Contraseña"
          ></v-checkbox>

          </v-col>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="pinRulescomputed"
              color="primary"
              type="submit"
              @click="validate"
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-card>
    </v-dialog>

    <v-dialog
      v-model="DialogResetPASS"
      persistent
      max-width="290"
    >
      <v-card>
        <v-toolbar
              color="primary"
              dark
            >
              Actualizar Contraseña
            </v-toolbar>

        <v-card-text class="mt-3 text-h6">Por favor digite una nueva contraseña para inicio de sesión.</v-card-text>
        
        <v-form
          v-on:submit.prevent="UpdatePASS()"
          ref="form"
          lazy-validation
        >
          <v-col
            cols="12"
          >
            <v-text-field
              :type="tipopassword ? 'text':'password'"
              v-model="New.Pass"
              label="Contraseña"
              hint="Escriba nueva contraseña para inicio de sesión."
              persistent-hint
              :loading="New.Pass == '' ? true : false"
              :rules="passRules"
            ></v-text-field>

            <v-checkbox
            class="mt-n1"
            v-model="tipopassword"
            label="Ver Contraseña"
          ></v-checkbox>

          </v-col>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="passRulescomputed"
              color="primary"
              type="submit"
              @click="validate"
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapState, mapGetters, mapActions} from 'vuex'
import carrusel from '@/components/carrusel';

export default {
  
  components: {
    carrusel
  },

  created() {
      this.DialogResetPIN = this.DatosdeUsuario.resetpin
      this.DialogResetPASS = this.DatosdeUsuario.resetpass
      this.New.id = this.DatosdeUsuario.id
  },

  data: () => ({
    tipopin: false,
    tipopassword: false,
    New: {id: null, Pass: '', Pin: '', Accion: ''},
    DialogResetPIN: null,
    DialogResetPASS: null,

    pinRules: [
        v => !!v || 'PIN Requerido',
        v => (v && v.length == 4) || 'PIN debe tener 4 caracteres',
        v => /^[0-9]+$/.test(v) || 'El PIN debe ser numerico.',
      ],
    passRules: [
        v => !!v || 'Contraseña Requerida',
        v => /^[@]+/.test(v) || 'Contraseña debe comenzar con @',
        v => (v && v.length >= 8) || 'Contraseña debe tener al menos 8 caracteres',
        v => /[A-Z]+/.test(v) || 'La contraseña debe tener al menos 1 letra mayuscula.',
        v => /[a-z]+/.test(v) || 'La contraseña debe tener al menos 1 letra minuscula.',
        v => /[0-9]+/.test(v) || 'La contraseña debe contener al menos 1 número.',
      ],
  }),

  watch: {
    
  },

  methods: {
    ...mapActions(['CambiarCredenciales']),

    UpdatePASS(){
      this.New.Accion = 'Pass'
      this.CambiarCredenciales(this.New)
    },

    UpdatePIN(){
      this.New.Accion = 'Pin'
      this.CambiarCredenciales(this.New)
    },

     validate () {
        this.$refs.form.validate()
      },
  },

  computed:{
      ...mapState(['token']),
      ...mapGetters(['DatosdeUsuario']),

      pinRulescomputed(){
        var bandera = false
        var tamano = this.New.Pin.length
        var mayus = this.New.Pin.match(/[A-Z]/g)
        var minus = this.New.Pin.match(/[a-z]/g)
        var num = this.New.Pin.match(/^[0-9]+$/g)

        if(tamano != 4 || mayus != null || minus != null || num == null)
          bandera = true

        return bandera
      },

      passRulescomputed(){
        var bandera = false
        var arroba = this.New.Pass.slice(0).indexOf('@')
        var tamano = this.New.Pass.length
        var mayus = this.New.Pass.match(/[A-Z]/g)
        var minus = this.New.Pass.match(/[a-z]/g)
        var num = this.New.Pass.match(/[0-9]/g)

        if(arroba != 0 || tamano < 8 || mayus == null || minus == null || num == null)
          bandera = true

        return bandera
      }
  }
}
</script>
