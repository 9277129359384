<template>
<div>
  <v-carousel
  cycle
  continuous
  height="100%"
  interval="3000"
  ><!--350-->
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="require(`@/assets/slider/${item.src}.png`)"
      reverse-transition="fade-transition"
      
    ></v-carousel-item>
  </v-carousel>
</div>
</template>

<script>
  export default {
    data () {
      return {
        items: [
          {
            src: 'BannerAfiliacionesEps',
          },
          {
            src: 'BannerCertificadoIngresos',
          },
          {
            src: 'BannerCertificadoTradicionVehiculo',
          },
          {
            src: 'BannerCertificadoTradicionyLibertad',
          },
          {
            src: 'BannerServiciosHogar',
          },
          {
            src: 'Entretenimiento',
          }
        ],
      }
    },
  }
</script>